html {
  scroll-behavior: smooth;
}
html, body {
  overflow-x:hidden;
  font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
}
.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  color: #ecf0ff;
}
a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.deadLink {
  text-decoration: line-through;
  color: white;
}

.comp-wrapper {
  width: 100%;
  height: auto;
}

#landing {
  position: relative;
  text-align: center;
  height: 100vh;
  width: 100%;
  background-color: #282c34;
  min-height: 37.5rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
#landing h2{
  margin: 0;
}
h1 {
  font-size: 4rem; 
}
.navMobileContainer{
  width: 9.375rem;
  height: 18.4375rem;
  // border: 1px solid white;
  display: none;
  flex-flow: column nowrap;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 9999;
  transition: left 0.4s ease-in-out, background-color 0.7s;
}
.bar1, .bar2, .bar3 {
  width: 3.125rem;
  height: 0.3125rem;
  background-color: #D7DFFF;
  margin: 0.375rem 0;
  transition: 0.4s;
}
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}
.change .bar2 {opacity: 0;}
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}
.container {
  display: inline-block;
  cursor: pointer;
  padding: 0.625rem 0 0 0.625rem;
}
.menuMobile{
  width: 100%;
  height: auto;
  margin-top: 0.625rem;
  position: absolute;
  top: 2.8125rem;
  left: 0;
  transition: left .4s ease-in-out;
}
.menuItem{
  font-size: 1.5rem;
  line-height: 3rem;
  margin: 0;
  padding-left: 1.25rem;
}
.hidden{
  left: -9.375rem;
}
.navMobileLinksContainer{
  list-style-type: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.navMobileLinksContainer a{
  width: 100%;
  height: auto;
  text-align: left;
  // padding: 0 8% 0.4375rem 12%;
  margin: none;
  // border-top: 1px solid rgb(105, 105, 163);
  // background-color: hsla(0, 0%, 0%, 0.767);
}
.navMobileLinksContainer a :hover{
  background-color: rgba(255, 255, 255, 0.151);
}
.landingEmail{
  padding: 0.375rem 0.8125rem 0.625rem;
}
.emailWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .2s ease;
}
.landingBarMobile{
  position: absolute;
  top: 0.5625rem;
  right: 0.5625rem;
  display: none;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
}
.linkedinLogo{
  width: 1.875rem;
  height: 1.875rem;
}
.linkedinWrapper{
  // border: 1px solid red;
  width: 3.125rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: rgba(255, 255, 255, 0.25);
  transition: background-color .2s ease;
  
}

// DESKTOP NAVBAR
#desktopNavbar{
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  transition: top .5s ease-in-out; 
  border-bottom: 3px solid rgba(12, 16, 41, 0.322);
}
.landingBar{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1.5rem;
  background-color: #282C34;
}
.gradient{
  // position: fixed;
  // top: 0px;
  // left: 650px;
  width: 14rem;
  height: 100%;
  background-image: linear-gradient(to right, #D8DBDB, #282C34);
}
.navDesktopContainer{
  position: relative;
  // border: 1px solid green;
  width: 100vw; // TODO: investigate if any vw bugs exist
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  background-color: #d8dbdb;

  a {
    &:first-of-type {
      margin: 0 0 0 1.5rem;
    }
    &:last-of-type {
      margin-right: auto;
    }
  }
  
}
.desktop{
  // border: 1px double gray;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 1.25rem;
  // margin-right: 20px;
  // border-radius: 5px;
  color: black;
  // background-color: blue;
  transition: background-color .4s;

}
.linkedinWrapper :hover{
  background-color: #ffffff8c;
}
.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #000000;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 0.1875rem;
  bottom: 0rem;
  left: 0;
  background-color: #396ed6;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
#projects {
  display: flex;
  flex-flow: column nowrap;
  background-color: #353a44;
  width: 100%;
  height: auto;
  padding-bottom: 4.375rem;
  align-items: flex-start;
  position: relative;
}
.comp-wrapper h2 {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
}


.arrowWrapper {
  width: 1.875rem;
  height: 1.875rem;
  border: 3px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 45%;
  z-index: 99;
  cursor: pointer;
}

.arrow {
  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 0.3125rem;
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.leftArrowWrapper {
  left: 0.1875rem;
}
.rightArrowWrapper {
  right: 0.1875rem;
}

.projectContainer {
  height: 36.875rem;
  position: relative;
  overflow: hidden;
}
.tileWrapper{
  // border: 1px solid red;
  height: 100%;
  width: auto;
  position: absolute;
  // left: 0px;
  transition: left .5s;
}
.projectTileContainer {
  // min-width: 280px;
  width: 17.5rem;
  height: 95%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  // margin-left: 35px;
  color: black;
  border: 1px solid rgb(61, 61, 82);
  background-color: rgb(216, 219, 219);
  box-shadow: 5px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.projectTileContainer p{
  font-size: 1.1rem;
}
.projName {
  font-size: 1.75rem;
  font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
  width: 100%;
  text-align: center;
  // position: absolute;
  top: 0.9375rem;
  color: black;
  // padding: 0;
  margin: 0.625rem;
}
.projectImg {
  object-fit: contain;
  overflow: hidden;
  // max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}
.imgWrapper {
  border-radius: 50%;
  width: 12.5rem;
  height: 12.5rem;
  border: 2px solid rgb(204, 204, 204);
  margin: 0 1.25rem;
  // margin-top: 4.375rem;
}
.github {
  border-radius: 50%;
  border: 2px solid rgb(121, 120, 120);
}
.tech{
  color:rgb(143, 21, 21);
  font-weight: bold;
  text-align: center;
  width: 100%;
}
.desc{
  margin-left: 0.5rem;
}
.tileLinks{
  position: absolute;
  bottom: 2.1875rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  // border: 1px solid red;
}
span.tileLinks *{
  color: rgb(8, 61, 175);
  font-weight: bold;
}
.tileLinks{
  margin-bottom: 0.9375rem;
}
.projectBody {
  display: flex;
  // height: 100%;
  max-width: 31.25rem;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  // border: 1px solid green;
  margin: 20px;
}
.date{
  position: absolute;
  bottom: 0;
  right: 1rem;
  color: black;
}

#experience {
  display: flex;
  flex-flow: column nowrap;
  background-color: #282C34;
  width: 100%;
  height: auto;
  padding-bottom: 6.25rem;
  align-items: center;
  padding: 0.3125rem;
}
.verticalTimeline{
  width: 100%;
  height: auto;
}
.iconWrapper{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}
.workImg{
  object-fit: contain;
  width: 100%;
  height: 100%;

}
.tlDate{
  color: white;
}

#about {
  display: flex;
  flex-flow: column nowrap;
  background-color: #353a44;
  width: 100%;
  height: auto;
  padding-bottom: 6.25rem;
  align-items: center;
  position: relative;
}
.aboutContainer{
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
}
.textContainer{
  width: 60%;
}
.headshotContainer{
  width: 30vw;
  height: 30vw;
  max-width: 18.75rem;
  max-height: 18.75rem;
  border: 3px solid #f3f5ff;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 0;
}
.headshot{
  object-fit: contain;
  height: 100%;
}
.textContainer h3{
  font-size: 2rem;
  margin: 0;
}
.textContainer h4{
  margin: 0 0 1.875rem;
}


.downloadResume{
  margin-top: 2.5rem;
  width: 6.875rem;
  height: 2.5rem;
  border-radius: 4px;
  background-color: rgb(103, 167, 209);
  border: 1px solid rgb(196, 217, 255);
  color: white;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.1875rem;
}


#connect {
  display: flex;
  flex-flow: column nowrap;
  background-color: #282C34;
  width: 100%;
  height: auto;
  padding-bottom: 100px;
  align-items: center;
  padding-bottom: 1.25rem;
}
.connectForm{
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 31.25rem;
  // border: 1px solid red;
}
.connectForm input{
  margin-bottom: 0.625rem;
}
.connectForm label{
  margin-bottom: 0.625rem;
}
.connectForm textarea{
  width: 100%;
  // max-width: 400px;
  min-height: 5rem;
}
.nameEmail{
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  // border: 1px solid green;
  justify-content: space-between;
  // align-items: space-between;
}
.email, .name, .message{
  width: 45%;
  display: flex;
  flex-flow: column nowrap;
  // border: 1px solid ;
}
.message{
  width: 100%;
}
.submit{
  width: 100%;
  display: flex;
  justify-content: center;
}
.submitBtn{
  margin: 0.9375rem;
  width: 7.5rem;
}
.messageNotification{
  height: 1.875rem;
  margin: 0;
  padding: 0;
}
#footer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4.375rem;
  position: relative;
  background-color: #353a44;
  border-top: 3px double rgba(255, 255, 255, 0.26);
}
.signature{
  color: white;
  width: 6.25rem;
  text-align: center;
  margin-right: 1.25rem;
}

// MEDIA QUERIES
@media screen and (max-width: 1169px) {
  // #experience{
  //   height: 1280px;
  // }  
  .tlDate{
    color: black
  }
}
@media screen and (max-width: 1026px) {
  .navMobileContainer{
    display: flex;
  }
  #desktopNavbar{
    display: none;
  }
  .gradient{
    display: none;
  }
  .landingBar{
    position: absolute;
    top: 2vw;
    right: 2vw;
  }
  .landingBarMobile{
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .bm-burger-button {
    width: 45px;
    height: 30px;
    left: 30px;
    top: 30px;
  }
 
  .aboutContainer{
    flex-flow: column nowrap;
  }
  .headshotContainer{
    order: 1
  }
  .textContainer{
    order: 2;
    width: 80%;
  }
}

@media screen and (max-width: 450px) {
  h1 {
    font-size: 3rem;
  }
 
  .projectContainer{
    margin-left: 2.1875rem;
  }
}
@media screen and (max-width: 410px) {
  .projectContainer{
    margin-left: 1.5625rem;
  }
}

@media screen and (max-width: 375px) {
  .projectContainer{
    margin-left: 0.625rem;
  }
}
